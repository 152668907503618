// comjoo colors
$white: #fff;
$black: #000;
$black-font: rgba(0, 0, 0, 0.65);
$grey-dark: #313237;
$grey-dark-bg: #393a40; //4C4D50 and 494141;
$grey-dark-border: #777;
$grey-dark-color: #7d7d7d;
$grey-dark-half: rgba(49, 50, 55, 0.5);
$grey-sidebar-item-bg: rgba(119, 119, 119, 0.5);
$grey-dark-light-opacity: rgba(49, 50, 55, 0.2);
$grey-light-background: #d9d9d9;
$grey-medium: #eef0f4;
$grey-light: #f2f4f8;
$white-grey-light-bg: #f9fafa;
$red-medium: #f76a6a;
$red-bright: #f76a6a;
$rose: #f8607a;
$blue-link: #6b42a2;
$blue-bright: #6b42a2;
$blue-bright-bg: #6b42a2;

// color for states
$orange: #f5ad42; // state color e.g. health
$yellow: #d4b106; // state color e.g. health
$yellow-green: #a0d911; // state color e.g. health d1e451
$green: #52c41a; // state color e.g. health
$yellow-progress: #ffefac; // e.g. flow-task
$yellow-accepted: rgba(255, 194, 13, 0.5);
$green-done: #5cf94d66; // e.g. flow-task
$green-approved: rgba(152, 232, 190, 0.7);
$red-declined: rgba(255, 26, 26, 0.4); // e.g. flow-task
$grey-daft: #c5cde480; // e.g. flow-task
$dark-table-state: #3b5769; // e.g. flow-task

$shadow-grey: 0 2px 3px 0 rgba(0, 0, 0, 0.14);
$shadow-grey-right: 2px 2px 3px 0 rgba(0, 0, 0, 0.14);
$shadow-preview-items: 0px 1px 2px $grey-sidebar-item-bg,
  0px 1px 2px $grey-sidebar-item-bg;
$shadow-text-dark: 0 0 5px $black;

$space-s: 0.5rem;
$space-m: 1rem;
$space-l: 1.5rem;

$top-menu-height: 77px;

// the following colors are preparation for theming
$primary-color: #00599a;
$success-color: #12e066;
$body-background: $grey-light;

// export var to used in js files
:export {
  white: $white;
  black: $black;
  blackGrey: $grey-dark;
  greyDark: $grey-dark-color;
  greyMedium: $grey-dark-half;
  greyLightMedium: $grey-medium;
  greyLight: $grey-light;
  blueBrightBG: $blue-bright-bg;
  rose: $rose;
  red: $red-medium;
  redDeclined: $red-declined;
  orange: $orange;
  yellow: $yellow;
  yellowGreen: $yellow-green;
  yellowProgress: $yellow-progress;
  yellowUpdatedProgress: $yellow-accepted;
  green: $green;
  greenDone: $green-done;
  bodyBackground: $body-background;
  greenApproved: $green-approved;
  primaryColor: $primary-color;
  successColor: $success-color;
}
