.dtb-init-loader,
.fanta {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  img {
    width: 30vw;
    svg {
      fill: red;
    }
    //filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg)
    //brightness(118%) contrast(119%);
  }

  h1 {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    color: rgba(0, 0, 0, 0.3);
    font-size: 3vw;
    background: gray;
    background: #003a6c !important;
    color: #ccc;
  }

  h1:before {
    content: attr(data-text);
    position: absolute;
    overflow: hidden;
    max-width: 100vw;
    white-space: nowrap;
    color: #fff;
    animation: loading 1.1s linear;
  }

  @keyframes loading {
    0% {
      max-width: 0;
    }
  }
}
